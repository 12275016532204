exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cart-checkout-page-js": () => import("./../../../src/pages/cart/CheckoutPage.js" /* webpackChunkName: "component---src-pages-cart-checkout-page-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart/[...].js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-cart-submitted-page-js": () => import("./../../../src/pages/cart/SubmittedPage.js" /* webpackChunkName: "component---src-pages-cart-submitted-page-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-categories-graphql-js": () => import("./../../../src/templates/categories-graphql.js" /* webpackChunkName: "component---src-templates-categories-graphql-js" */),
  "component---src-templates-product-graphql-js": () => import("./../../../src/templates/product-graphql.js" /* webpackChunkName: "component---src-templates-product-graphql-js" */)
}

